import React, { useEffect, useState } from "react";
import {
  fileTrayFullSharp,
  fileTrayFullOutline,
  searchSharp,
  searchOutline,
  notificationsSharp,
  notificationsOutline,
  addCircle,
  personCircleSharp,
  personCircleOutline,
} from "ionicons/icons";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route, Redirect } from "react-router";
import TabPost from "./TabPostTask";
import TabBrowse from "./TabBrowse";
import TabTasks from "./TabTasks";
import TabNotifications from "./TabNotifications";
import TabAccount from "./TabAccount";
import { titleCase } from "../../utils/helpers";
const MainMenu: React.FC<any> = () => {
  // const [selectedTab, setSelectedTab] = useState("post");
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    // console.log("selectedTab >>>>", selectedTab, window.location.pathname)
    const pathname = titleCase(window.location.pathname.replaceAll("/", ""));
    setSelectedTab(pathname);
  }, []);

  const onRedirect = () => {
    // setSelectedTab("Account")
    // return <Redirect to="/account" />
    setSelectedTab("Post");
    return <Redirect to="/post" />;
  };
  return (
    <>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar>
          {selectedTab === "Post" ? (
            <IonTitle> Post a task</IonTitle>
          ) : (
            <IonTitle className="">{selectedTab}</IonTitle>
          )}
        </IonToolbar>
      </IonHeader>

      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact={true} path="/post" component={() => <TabPost />} />
            <Route
              exact={true}
              path="/browse"
              component={() => <TabBrowse />}
            />
            <Route exact={true} path="/tasks" component={() => <TabTasks />} />

            <Route
              exact={true}
              path="/notifications"
              component={() => <TabNotifications />}
            />
            <Route
              exact={true}
              path="/account"
              component={() => <TabAccount />}
            />

            <Route
              exact={true}
              path="/"
              render={() => <Redirect to="/account" />}
            />
            <Route
              exact={true}
              path="/home"
              render={() => <Redirect to="/account" />}
            />
            <Route render={onRedirect} />
          </IonRouterOutlet>

          <IonTabBar
            slot="bottom"
            // hidden={platform.name === "web"} //-- temporarily disabled
            selectedTab="account"
            onIonTabsDidChange={(e) =>
              // setSelectedTab(e.detail.tab.toLowerCase())
              setSelectedTab(e.detail.tab)
            }
            className="pad-5-tb"
          >
            <IonTabButton tab="Tasks" href="/tasks">
              <IonIcon
                icon={
                  selectedTab === "Tasks"
                    ? fileTrayFullSharp
                    : fileTrayFullOutline
                }
              />
              <IonLabel>Tasks</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Notifications" href="/notifications">
              <IonIcon
                icon={
                  selectedTab === "Notifications"
                    ? notificationsSharp
                    : notificationsOutline
                }
              />
              <IonLabel>Notifications</IonLabel>
            </IonTabButton>

            <IonTabButton tab="Post" href="/post">
              <IonIcon icon={addCircle} className="at-size-44" />
            </IonTabButton>
            <IonTabButton tab="Browse" href="/browse">
              <IonIcon
                icon={selectedTab === "Browse" ? searchSharp : searchOutline}
              />
              <IonLabel>Browse</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Account" href="/account">
              <IonIcon
                icon={
                  selectedTab === "Account"
                    ? personCircleSharp
                    : personCircleOutline
                }
              />
              <IonLabel>Account</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </>
  );
};

export default MainMenu;
