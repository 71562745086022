import React, { useEffect, useState } from "react";
import {
  IonLabel,
  IonContent,
  IonItem,
  IonButton,
  IonIcon,
  useIonAlert,
} from "@ionic/react";
import { mail, documentLock, helpCircle, power } from "ionicons/icons";
import Modals from "./modals/Modals";
import { MODALS } from "./modals";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import useAuth from "../../hooks/useAuth";
const TabAccount: React.FC<any> = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<any>("");
  const [presentAlert] = useIonAlert();

  const { auth  } = useAuth();
  // const [user, setUser] = useState<any>({
  //   userName: "",
  //   name: "",
  //   imageUrl: "",
  // });

  // const persistAllData: any = (data: any) => {
  //   return Object.keys(data).map((key: any) => {
  //     // console.log("data[key] ", data[key]);
  //     return window.localStorage.getItem(key )?.toString();
  //   });
  // };

  // useEffect(() => {
  //   // setUser(persistAllData(user));

  //   console.log(">>>>auth", auth)
  //   setUser({
  //     name: window.localStorage.getItem("name")?.toString(),
  //     imageUrl: window.localStorage.getItem("imageUrl")?.toString(),
  //   });
  // }, []);

  const showModal = (modalName: any) => {
    setCurrentModal(MODALS[modalName].toString().toLowerCase());
    setIsModalOpen(true);
  };

  const onSignOutGoogle = async () => {
    await GoogleAuth.signOut();
    // setAuth({});
  };
  const onSignOut = async () => {
    if (window.localStorage.getItem("isGoogle")) {
      onSignOutGoogle();
    }
    window.localStorage.clear();
    window.location.href = "/";
  };

  return (
    <>
      {currentModal && (
        <Modals
          component={currentModal}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}

      <IonContent className="ion-padding " scrollEvents={true}>
        <IonItem
          className="at-pad-20-bottom cursor-pointer"
          color="none"
          lines="none"
          detail={true}
          onClick={() => {
            showModal(MODALS.ACCOUNT);
          }}
        >
          <img
            alt=""
            className="task-image"
            // slot="start"
            // src="/assets/images/auric_gavin.jpg"
            // src={user?.imageUrl}
            src={auth?.imageUrl}
            width="60"
          ></img>
          {/* <div
          style={{ backgroundImage: `url(${profilePhoto})` }}
          // style={divImage}
          className="photo-container"
        >
          {provider && provider?.photo + "" === "" && (
            <IonIcon icon={personOutline} className="size-36 profile-photo" />
          )}
        </div> */}

          <div className="pad-20-left">
            {/* <IonLabel className="at-size-18"> {user?.name}</IonLabel> */}
            <IonLabel className="at-size-18"> {auth?.name}</IonLabel>
            <IonLabel className="at-opacity-5">+63 977 1794 521</IonLabel>
            <br />
          </div>
        </IonItem>

        <IonItem lines="none" color="none">
          <IonButton
            className="at-list-button"
            onClick={() => {
              showModal(MODALS.SUPPORT);
            }}
          >
            <IonIcon
              className="pad-10"
              color="primary"
              icon={mail}
              size="small"
            />
            <IonLabel className="pad-10-left">Support</IonLabel>
          </IonButton>
        </IonItem>
        <IonItem lines="none" color="none" disabled={true}>
          <IonButton
            className="at-list-button"
            onClick={() => {
              showModal(MODALS.TERMS);
            }}
          >
            <IonIcon
              className="pad-10"
              color="primary"
              icon={documentLock}
              size="small"
            />
            <IonLabel className="pad-10-left">Terms & Conditions</IonLabel>
          </IonButton>
        </IonItem>
        <IonItem lines="none" color="none" disabled={true}>
          <IonButton
            className="at-list-button"
            onClick={() => {
              showModal(MODALS.ACCOUNT);
            }}
          >
            <IonIcon
              className="pad-10"
              color="primary"
              icon={helpCircle}
              size="small"
            />
            <IonLabel className="pad-10-left">FAQs & Terms</IonLabel>
          </IonButton>
        </IonItem>
        <IonItem lines="none" color="none">
          <IonButton
            className="at-list-button"
            // onClick={onSignOut}
            onClick={() =>
              presentAlert({
                header: "LOGOUT",
                message: "Are you sure you want to logout?",
                cssClass: "custom-alert",
                buttons: [
                  {
                    text: "No",
                    cssClass: "alert-button-cancel",
                  },
                  {
                    text: "Yes",
                    cssClass: "alert-button-confirm",
                    // handler: () => {
                    //   onSignOut();
                    // },
                    handler: onSignOut,
                  },
                ],
              })
            }
          >
            <IonIcon
              className="pad-10"
              color="primary"
              icon={power}
              size="small"
            />
            <IonLabel className="pad-10-left">Logout</IonLabel>
          </IonButton>
        </IonItem>
      </IonContent>
      {/* <>{console.log("imageUrl >>>", user?.imageUrl)} </> */}
    </>
  );
};

export default TabAccount;
