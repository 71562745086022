import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonTextarea,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {
  arrowBackOutline,
  phonePortraitOutline,
  person,
  mail,
  informationCircle,
} from "ionicons/icons";
import useAuth from "../../../../hooks/useAuth";

const ModalAccount: React.FC<any> = ({
  isOpen,
  onClose,
  // setResult
}) => {
  const { auth } = useAuth();
  const [state, setState] = useState<any>({
    email: "avtividad@yahoo.com",
    name: "Adonis Tividad",
    mobile_no: "+91771794521",
    address: "Antipolo, Rizal",
    imageUrl: "/assets/images/auric_gavin.jpg",
  });

  useEffect(() => {
    const {name, imageUrl} = auth;
    setState({ ...state, name, imageUrl });
  }, []);

  const onDismiss = () => {
    // setResult({ lat: center[0], lon: center[1], name: placeName });
    onClose(1);
  };
  const saveInfo = () => {
    alert("Info updated \n" + JSON.stringify(state));
    onClose(1);
  };
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} at-default>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>My Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding pad-60-bottom">
        <IonCard color="none">
          <IonCardContent>
            <IonItem
              className="at-pad-20-bottom cursor-pointer"
              color="none"
              lines="none"
            >
              <img
                alt=""
                className="task-image"
                // slot="start"
                src={state?.imageUrl}
                width="120"
              ></img>
            </IonItem>
            <IonItem color="none">
              <IonLabel position="floating">Mobile Number</IonLabel>
              <IonIcon
                color="primary"
                icon={phonePortraitOutline}
                size="small"
                slot="start"
              />
              <IonInput
                value={state.mobile_no}
                // onFocus={(e) => setState({ ...state, mobile_no: "" })}
                onIonChange={(e) =>
                  setState({ ...state, mobile_no: e.detail.value! })
                }
              />
            </IonItem>
            <IonItem color="none">
              <IonLabel position="floating">Full Name</IonLabel>
              <IonIcon
                color="primary"
                icon={person}
                size="small"
                slot="start"
              />
              <IonInput
                value={state.name}
                // onFocus={(e) => setState({ ...state, name: "" })}
                onIonChange={(e) =>
                  setState({ ...state, name: e.detail.value! })
                }
              />
            </IonItem>{" "}
            <IonItem color="none">
              <IonLabel position="floating">Email Address</IonLabel>
              <IonIcon color="primary" icon={mail} size="small" slot="start" />
              <IonInput
                value={state.email}
                // onFocus={(e) => setState({ ...state, email: "" })}
                onIonChange={(e) =>
                  setState({ ...state, email: e.detail.value! })
                }
                readonly={true}
                disabled={true}
              />
            </IonItem>
            <IonItem color="none">
              <IonLabel position="floating">Address</IonLabel>
              <IonIcon
                color="primary"
                icon={informationCircle}
                size="small"
                slot="start"
              />
              <IonTextarea
                value={state.address}
                // onFocus={(e) => setState({ ...state, address: "" })}
                onIonChange={(e) =>
                  setState({ ...state, address: e.detail.value! })
                }
                rows={3}
              />
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <div className="at-menu-bottom">
        <IonButton expand="block" onClick={saveInfo}>
          Update Info
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ModalAccount;
