import ModalAccount from "./account/ModalAccount";
import ModalFAQs from "./account/ModalFAQs"; 
import ModalSupport from "./account/ModalSupport";
import ModalTerms from "./account/ModalTerms";
import ModalTaskStatus from "./tasks/ModalTaskStatus";
/*****
 Components name (in lower case) must be equal to MODALS name (in upper case)
 eg. job_status === JOB_STATUS
*/
export const Components: any = {
  account: ModalAccount, 
  support: ModalSupport,
  terms: ModalTerms,
  faqs: ModalFAQs,
  task_status: ModalTaskStatus,
};
export enum MODALS {
  ACCOUNT, 
  SUPPORT,
  TERMS,
  FAQS,
  TASK_STATUS,
}
