import { IonCard, IonCardContent } from "@ionic/react";
import React, { useState } from "react";
import AtListItem from "../../../common/AtListItem";
import ModalBiddingDetails from "./bidding/ModalBiddingDetails";

const data = [
  {
    id: 1,
    photo: "randy_legaspi.jpg",
    title: "Interior Decorations",
    title2: "19 Dec",
    subtitle: (
      <>
        <span className="at-bold at-size-14">9</span> offers |{" "}
        <span className="at-bold at-size-14">4</span> shortlisted |{" "}
        <span className="at-bold at-size-14">4</span> declined
      </>
    ),
    subtitle2: "11:30 AM",
    remarks2: "₱100 per hour",
    subremarks:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio fugit maxime id nemo alias quam delectus nesciunt nobis doloribus at, sit velit eligendi quisquam aut nulla voluptates rem! Ullam, aliquam?",
    cssItem: "at-border-primary",
    cssTitle2: "",

    cost: "₱100 per hour",
    address: "Camiling, Tarlac, Philippines",
    latlong: "",
  },
  {
    id: 2,
    photo: "geraldine_tividad.jpg",
    title: "Bathroom fittings",
    title2: "28 Feb",
    subtitle: (
      <>
        <span className="at-bold at-size-14">OPEN</span> to offers
      </>
    ),
    subtitle2: "10:25 AM",
    remarks2: "₱200 per hour",
    subremarks:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio maiores consectetur, dignissimos optio accusantium error a assumenda velit. Vero quidem architecto debitis quis quasi fuga totam sed nisi sequi soluta!",
    cssItem: "",
    cssTitle2: "",

    cost: "₱200 per hour",
    address: "Iguig, Cagayan Valley, Philippines",
    latlong: "",
  },
  {
    id: 3,
    photo: "auric_gavin.jpg",
    title: "Solar Panel Installation",
    title2: "30 Nov",
    subtitle: (
      <>
        <span className="at-bold at-size-14">25</span> offers |{" "}
        <span className="at-bold at-size-14">10</span> shortlisted |{" "}
        <span className="at-bold at-size-14">5</span> declined
      </>
    ),
    subtitle2: "10:25 AM",
    remarks2: "₱250 per hour",
    subremarks: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. ",
    cssItem: "",
    cssTitle2: "",

    cost: "₱250 per hour",
    address: "San Jose, Antipolo, Philippines",
    latlong: "",
  },
  {
    id: 1,
    photo: "randy_legaspi.jpg",
    title: "Interior Decorations",
    title2: "19 Dec",
    subtitle: (
      <>
        <span className="at-bold at-size-14">9</span> offers |{" "}
        <span className="at-bold at-size-14">4</span> shortlisted |{" "}
        <span className="at-bold at-size-14">4</span> declined
      </>
    ),
    subtitle2: "11:30 AM",
    remarks2: "₱100 per hour",
    subremarks:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio fugit maxime id nemo alias quam delectus nesciunt nobis doloribus at, sit velit eligendi quisquam aut nulla voluptates rem! Ullam, aliquam?",
    cssItem: "at-border-primary",
    cssTitle2: "",

    cost: "₱100 per hour",
    address: "Camiling, Tarlac, Philippines",
    latlong: "",
  },
  {
    id: 2,
    photo: "geraldine_tividad.jpg",
    title: "Bathroom fittings",
    title2: "28 Feb",
    subtitle: (
      <>
        <span className="at-bold at-size-14">5</span> offers |{" "}
        <span className="at-bold at-size-14">1</span> shortlisted |{" "}
        <span className="at-bold at-size-14">1</span> declined
      </>
    ),
    subtitle2: "10:25 AM",
    remarks2: "₱200 per hour",
    subremarks:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio maiores consectetur, dignissimos optio accusantium error a assumenda velit. Vero quidem architecto debitis quis quasi fuga totam sed nisi sequi soluta!",
    cssItem: "",
    cssTitle2: "",

    cost: "₱200 per hour",
    address: "Iguig, Cagayan Valley, Philippines",
    latlong: "",
  },
  {
    id: 3,
    photo: "auric_gavin.jpg",
    title: "Solar Panel Installation",
    title2: "30 Nov",
    subtitle: (
      <>
        <span className="at-bold at-size-14">25</span> offers |{" "}
        <span className="at-bold at-size-14">10</span> shortlisted |{" "}
        <span className="at-bold at-size-14">5</span> declined
      </>
    ),
    subtitle2: "10:25 AM",
    remarks2: "₱250 per hour",
    subremarks: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. ",
    cssItem: "",
    cssTitle2: "",

    cost: "₱250 per hour",
    address: "San Jose, Antipolo, Philippines",
    latlong: "",
  },
  {
    id: 1,
    photo: "randy_legaspi.jpg",
    title: "Interior Decorations",
    title2: "19 Dec",
    subtitle: (
      <>
        <span className="at-bold at-size-14">9</span> offers |{" "}
        <span className="at-bold at-size-14">4</span> shortlisted |{" "}
        <span className="at-bold at-size-14">4</span> declined
      </>
    ),
    subtitle2: "11:30 AM",
    remarks2: "₱100 per hour",
    subremarks:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio fugit maxime id nemo alias quam delectus nesciunt nobis doloribus at, sit velit eligendi quisquam aut nulla voluptates rem! Ullam, aliquam?",
    cssItem: "at-border-primary",
    cssTitle2: "",

    cost: "₱100 per hour",
    address: "Camiling, Tarlac, Philippines",
    latlong: "",
  },
  {
    id: 2,
    photo: "geraldine_tividad.jpg",
    title: "Bathroom fittings",
    title2: "28 Feb",
    subtitle: (
      <>
        <span className="at-bold at-size-14">5</span> offers |{" "}
        <span className="at-bold at-size-14">1</span> shortlisted |{" "}
        <span className="at-bold at-size-14">1</span> declined
      </>
    ),
    subtitle2: "10:25 AM",
    remarks2: "₱200 per hour",
    subremarks:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio maiores consectetur, dignissimos optio accusantium error a assumenda velit. Vero quidem architecto debitis quis quasi fuga totam sed nisi sequi soluta!",
    cssItem: "",
    cssTitle2: "",

    cost: "₱200 per hour",
    address: "Iguig, Cagayan Valley, Philippines",
    latlong: "",
  },
  {
    id: 3,
    photo: "auric_gavin.jpg",
    title: "Solar Panel Installation",
    title2: "30 Nov",
    subtitle: (
      <>
        <span className="at-bold at-size-14">25</span> offers |{" "}
        <span className="at-bold at-size-14">10</span> shortlisted |{" "}
        <span className="at-bold at-size-14">5</span> declined
      </>
    ),
    subtitle2: "10:25 AM",
    remarks2: "₱250 per hour",
    subremarks: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. ",
    cssItem: "",
    cssTitle2: "",

    cost: "₱250 per hour",
    address: "San Jose, Antipolo, Philippines",
    latlong: "",
  },
  {
    id: 1,
    photo: "randy_legaspi.jpg",
    title: "Interior Decorations",
    title2: "19 Dec",
    subtitle: (
      <>
        <span className="at-bold at-size-14">9</span> offers |{" "}
        <span className="at-bold at-size-14">4</span> shortlisted |{" "}
        <span className="at-bold at-size-14">4</span> declined
      </>
    ),
    subtitle2: "11:30 AM",
    remarks2: "₱100 per hour",
    subremarks:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio fugit maxime id nemo alias quam delectus nesciunt nobis doloribus at, sit velit eligendi quisquam aut nulla voluptates rem! Ullam, aliquam?",
    cssItem: "at-border-primary",
    cssTitle2: "",

    cost: "₱100 per hour",
    address: "Camiling, Tarlac, Philippines",
    latlong: "",
  },
  {
    id: 2,
    photo: "geraldine_tividad.jpg",
    title: "Bathroom fittings",
    title2: "28 Feb",
    subtitle: (
      <>
        <span className="at-bold at-size-14">5</span> offers |{" "}
        <span className="at-bold at-size-14">1</span> shortlisted |{" "}
        <span className="at-bold at-size-14">1</span> declined
      </>
    ),
    subtitle2: "10:25 AM",
    remarks2: "₱200 per hour",
    subremarks:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio maiores consectetur, dignissimos optio accusantium error a assumenda velit. Vero quidem architecto debitis quis quasi fuga totam sed nisi sequi soluta!",
    cssItem: "",
    cssTitle2: "",

    cost: "₱200 per hour",
    address: "Iguig, Cagayan Valley, Philippines",
    latlong: "",
  },
  {
    id: 3,
    photo: "auric_gavin.jpg",
    title: "Solar Panel Installation",
    title2: "30 Nov",
    subtitle: (
      <>
        <span className="at-bold at-size-14">25</span> offers |{" "}
        <span className="at-bold at-size-14">10</span> shortlisted |{" "}
        <span className="at-bold at-size-14">5</span> declined
      </>
    ),
    subtitle2: "10:25 AM",
    remarks2: "₱250 per hour",
    subremarks: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. ",
    cssItem: "",
    cssTitle2: "",

    cost: "₱250 per hour",
    address: "San Jose, Antipolo, Philippines",
    latlong: "",
  },
];

const taskOffersData = [
  {
    id: 1,
    task_id: 1,
    provider_id: 1,
    category: "Plumbing",
    currency: "PHP",
    estimated_cost: 2500,
    estimated_time: 1,
    remarks: "New offer",
    documents: "\r\n1652858800322.jpg,1652858828620.jpg,1652860533273.jpg",
    images: "\r\n1652858800322.jpg,1652858828620.jpg,1652860533273.jpg",
    offer_datetime: "2022-05-18 16:44:13",
    offer_status: "shortlisted",
    status: "offer",
    ts: "2022-05-18T10:44:13.000Z",
    providers_profile: {
      id: 1,
      user_id: 1,
      name: "Auric Gavin",
      mobile_number: "+971501733176",
      photo: "auric_gavin.jpg",
      about: (
        <>
          <div>Title: Master Plumber</div>
          <div>Experience: 15 years in the plumbing industry</div>
          <div>
            Education: Associates degree in plumbing technology from XYZ
            Technical College
          </div>
          <div>Skills:</div>
          <p>
            Expertise in all aspects of residential and commercial plumbing,
            including installation, repair, and maintenance of pipes, fixtures,
            and appliances Proficiency in the use of various plumbing tools and
            equipment, such as pipe wrenches, pipe cutters, and hydro jetters
            Knowledge of local building codes and regulations Strong
            problem-solving skills, able to diagnose and fix plumbing issues
            efficiently and effectively Excellent customer service and
            communication skills, able to explain complex plumbing issues to
            clients in a clear and concise manner Professional Accomplishments:
          </p>
          <p>
            Successfully completed over 500 plumbing projects, including
            large-scale renovations and new construction projects Achieved a 97%
            customer satisfaction rate, consistently receiving positive feedback
            and referrals from clients Earned the title of Master Plumber,
            demonstrating a high level of knowledge and expertise in the
            industry
            <br />
            Availability: Available for emergency service 24/7, and able to
            schedule appointments for non-emergency plumbing projects within 48
            hours.
          </p>
        </>
      ),
      categories: "Electrical, Painting, Plumbing",
      transport: "bike,",
      address:
        "CipherBizz, Sheikh Mohammed Bin Zayed Road, International City Phase(2), International City, Dubai, 341296, United Arab Emirates",
      address_coord: null,
      distance: 1.25,
      country: "Philippines",
      state: null,
      city: "Balanga, Bataan",
      rating: 3.5,
      reviews: 25,
      profile_images: "1652858800322.jpg,1652858828620.jpg,1652860533273.jpg",
      doc_status: null,
      status: "pending",
      ts: "2021-09-11T07:53:02.000Z",
    },
  },
  {
    id: 2,
    task_id: 1,
    provider_id: null,
    category: "Plumbing",
    currency: "PHP",
    estimated_cost: 1000,
    estimated_time: 5,
    remarks: "New offer",
    documents: "doc1.pdf",
    images: "1652876180403.jpg",
    offer_datetime: "2022-05-19 12:02:57",
    offer_status: "declined",
    status: "offer",
    ts: "2022-05-19T06:02:57.000Z",
    providers_profile: {
      id: 2,
      user_id: 2,
      name: "Randy Legaspi",
      mobile_number: "0501111111",
      photo: "randy_legaspi.jpg",
      about:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here", making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for "lorem ipsum" will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
      categories: "Electrical, Painting, Plumbing",
      transport: null,
      address: null,
      address_coord: null,
      distance: 2.5,
      country: "Philippines",
      state: null,
      city: "Canlaon, Negros Oriental",
      rating: 4.0,
      reviews: 20,
      profile_images: "1652858800322.jpg,1652858828620.jpg,1652860533273.jpg",
      doc_status: null,
      status: "pending",
      ts: "2021-09-14T07:53:02.000Z",
    },
  },
  {
    id: 3,
    task_id: 1,
    provider_id: null,
    category: "Plumbing",
    currency: "PHP",
    estimated_cost: 500,
    estimated_time: 6,
    remarks: "New offer",
    documents: "doc1.pdf",
    images: "1652858828620.jpg",
    offer_datetime: "2022-05-19 12:03:01",
    offer_status: null,
    status: "offer",
    ts: "2022-05-19T06:03:01.000Z",
    providers_profile: {
      id: 3,
      user_id: 3,
      name: "Geraldine Tividad",
      mobile_number: "0502222222",
      photo: "geraldine_tividad.jpg",
      about:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here", making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for "lorem ipsum" will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
      categories: "Electrical, Painting, Plumbing",
      transport: null,
      address: null,
      address_coord: null,
      distance: 2.0,
      country: "Philippines",
      state: null,
      city: "Cadiz, Negros Occidental",
      rating: 3.0,
      reviews: 10,
      profile_images: "1652858800322.jpg,1652858828620.jpg,1652860533273.jpg",
      doc_status: null,
      status: "pending",
      ts: "2021-09-14T07:53:02.000Z",
    },
  },
  {
    id: 4,
    task_id: 1,
    provider_id: null,
    category: "Plumbing",
    currency: "PHP",
    estimated_cost: 800,
    estimated_time: 4,
    remarks: "New offer",
    documents: "doc1.pdf",
    images: "1652856667764.jpg",
    offer_datetime: "2022-05-19 12:03:03",
    offer_status: "shortlisted",
    status: "offer",
    ts: "2022-05-19T06:03:03.000Z",
    providers_profile: {
      id: 4,
      user_id: 4,
      name: "Geraldine Tividad",
      mobile_number: "0503333333",
      photo: "geraldine_tividad.jpg",
      about:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here", making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for "lorem ipsum" will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
      categories: "Electrical, Painting, Plumbing",
      transport: null,
      address: null,
      address_coord: null,
      distance: 2.3,
      country: "Philippines",
      state: null,
      city: "Tanjay, Negros Oriental",
      rating: 2.5,
      reviews: 15,
      profile_images: "1652858800322.jpg,1652858828620.jpg,1652860533273.jpg",
      doc_status: null,
      status: "pending",
      ts: "2021-09-14T07:53:02.000Z",
    },
  },
  {
    id: 5,
    task_id: 1,
    provider_id: null,
    category: "Plumbing",
    currency: "PHP",
    estimated_cost: 1234567890.99,
    estimated_time: 8,
    remarks: "New offer",
    documents: "doc1.pdf",
    images: "1652856524982.jpg",
    offer_datetime: "2022-05-19 12:03:09",
    offer_status: "declined",
    status: "offer",
    ts: "2022-05-19T06:03:09.000Z",
    providers_profile: {
      id: 5,
      user_id: 5,
      name: "Auric Gavin",
      mobile_number: "0504444444",
      photo: "auric_gavin.jpg",
      about:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here", making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for "lorem ipsum" will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
      categories: "Electrical, Painting, Plumbing",
      transport: null,
      address: null,
      address_coord: null,
      distance: 1.8,
      country: "Philippines",
      state: null,
      city: "Balanga, Bataan",
      rating: 4.6,
      reviews: 12,
      profile_images: "1652858800322.jpg,1652858828620.jpg,1652860533273.jpg",
      doc_status: null,
      status: "pending",
      ts: "2021-09-14T07:53:02.000Z",
    },
  },
  {
    id: 6,
    task_id: 1,
    provider_id: null,
    category: "Plumbing",
    currency: "PHP",
    estimated_cost: 400,
    estimated_time: 2,
    remarks: "New offer",
    documents: "doc1.pdf",
    images: "1652852950177.jpg,1652856394634.jpg",
    offer_datetime: "2022-05-19 12:03:11",
    offer_status: null,
    status: "offer",
    ts: "2022-05-19T06:03:11.000Z",
    providers_profile: {
      id: 6,
      user_id: 6,
      name: "Adonis Tividad",
      mobile_number: "0505555555",
      photo: "adonis_tividad.jpg",
      about:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here", making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for "lorem ipsum" will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
      categories: "Electrical, Painting, Plumbing",
      transport: null,
      address: null,
      address_coord: null,
      distance: 1.5,
      country: "Philippines",
      state: null,
      city: "Pasay",
      rating: 3.7,
      reviews: 17,
      profile_images: "1652858800322.jpg,1652858828620.jpg,1652860533273.jpg",
      doc_status: null,
      status: "pending",
      ts: "2021-09-14T07:53:02.000Z",
    },
  },
  {
    id: 7,
    task_id: 1,
    provider_id: null,
    category: "Electrical",
    currency: "PHP",
    estimated_cost: 300,
    estimated_time: 7,
    remarks: "New offer",
    documents: "doc1.pdf",
    images: "1652852917338.jpg",
    offer_datetime: "2022-05-19 12:05:10",
    offer_status: "shortlisted",
    status: "offer",
    ts: "2022-05-19T06:05:10.000Z",
    providers_profile: {
      id: 7,
      user_id: 7,
      name: "Randy Legaspi",
      mobile_number: "0506666666",
      photo: "randy_legaspi.jpg",
      about: (
        <>
          <p>
            Education: Associates degree in electrical technology from New York
            City College of Technology
          </p>
          <p>
            Experience: 5 years as an electrician, including experience in
            residential, commercial, and industrial settings
          </p>
          <p>
            Skills: Proficient in electrical wiring, installation, and repair,
            skilled in troubleshooting electrical issues, knowledgeable in codes
            and regulations, excellent communication and customer service skills
          </p>
          <p>
            Certifications: Licensed electrician in New York State, CPR and
            first aid certified
          </p>
          <p>
            Availability: Available for emergency callouts 24/7, flexible
            scheduling for regular appointments
          </p>
          <p>
            Additional Information: Highly reliable and dependable, always puts
            the safety of clients and their property first. Looking to join a
            team of professionals where I can continue to grow and learn in my
            career.
          </p>
        </>
      ),
      categories: "Electrical, Painting, Plumbing",
      transport: null,
      address: null,
      address_coord: null,
      distance: 1.7,
      country: "Philippines",
      state: null,
      city: "Bogo, Cebu",
      rating: 5,
      reviews: 25,
      profile_images: "1652858800322.jpg,1652858828620.jpg,1652860533273.jpg",
      doc_status: null,
      status: "pending",
      ts: "2021-09-14T07:53:02.000Z",
    },
  },
  {
    id: 8,
    task_id: 1,
    provider_id: null,
    category: "Painting",
    currency: "PHP",
    estimated_cost: 1500,
    estimated_time: 10,
    remarks: "New offer",
    documents: "doc1.pdf",
    images: "1652852185305.jpg",
    offer_datetime: "2022-05-19 12:05:12",
    offer_status: null,
    status: "offer",
    ts: "2022-05-19T06:05:12.000Z",
    providers_profile: {
      id: 8,
      user_id: 8,
      name: "Geraldine Tividad",
      mobile_number: "+971503908526",
      photo: "geraldine_tividad.jpg",
      about: (
        <>
          <p>Experience: 10 years as a professional painter</p>
          <p>
            Specialties: Interior and exterior painting, wallpapering, faux
            finishing, color consultation
          </p>
          <p>
            Education: Bachelor's degree in Fine Arts from New York University
          </p>
          <p>
            About Me: I have a passion for creating beautiful spaces through the
            use of color and texture. I take pride in my attention to detail and
            strive to exceed my clients' expectations with every project.
            Whether it's a simple freshening up or a full home renovation, I
            approach each job with the same level of dedication and
            professionalism. Let me help bring your vision to life and transform
            your home into the space you've always dreamed of.
          </p>
        </>
      ),
      categories: "Electrical, Painting, Plumbing",
      transport: null,
      address: null,
      address_coord: null,
      distance: 1.9,
      country: "Philippines",
      state: null,
      city: "Baybay, Leyte",
      rating: 4.8,
      reviews: 48,
      profile_images: "1652858800322.jpg,1652858828620.jpg,1652860533273.jpg",
      doc_status: null,
      status: "pending",
      ts: "2021-09-14T07:53:02.000Z",
    },
  },
  {
    id: 9,
    task_id: 1,
    provider_id: 2,
    category: "Plumbing",
    currency: "PHP",
    estimated_cost: 2500,
    estimated_time: 12,
    remarks:
      "A detailed and clear task description is really important because it lets Taskers know what you want done for your task so that you're both on the same page from the start. It also means that Taskers can come prepared with the correct equipment and materials for the job, ready to complete your job without any delays.",
    documents: "doc1.pdf",
    images: "1652856394634.jpg",
    offer_datetime: "2022-05-19 13:22:41",
    offer_status: "shortlisted",
    status: "offer",
    ts: "2022-05-19T07:22:41.000Z",
    providers_profile: {
      id: 9,
      user_id: 9,
      name: "Dinho Legaspi",
      mobile_number: "0501234567",
      photo: "dinho_legaspi.jpg",
      about: (
        <>
          <p>Title: Master Plumber</p>
          <p>Experience: 15 years in the plumbing industry</p>
          <p>
            Education: Associates degree in plumbing technology from XYZ
            Technical College
          </p>
          <p>Skills:</p>
          <p>
            Expertise in all aspects of residential and commercial plumbing,
            including installation, repair, and maintenance of pipes, fixtures,
            and appliances Proficiency in the use of various plumbing tools and
            equipment, such as pipe wrenches, pipe cutters, and hydro jetters
            Knowledge of local building codes and regulations Strong
            problem-solving skills, able to diagnose and fix plumbing issues
            efficiently and effectively Excellent customer service and
            communication skills, able to explain complex plumbing issues to
            clients in a clear and concise manner Professional Accomplishments:
          </p>
          <p>
            Successfully completed over 500 plumbing projects, including
            large-scale renovations and new construction projects Achieved a 97%
            customer satisfaction rate, consistently receiving positive feedback
            and referrals from clients Earned the title of Master Plumber,
            demonstrating a high level of knowledge and expertise in the
            industry
            <br />
            Availability: Available for emergency service 24/7, and able to
            schedule appointments for non-emergency plumbing projects within 48
            hours.
          </p>
        </>
      ),
      categories: "Electrical, Painting, Plumbing",
      transport: "bike",
      address:
        "Lube Express, Salah Al Din Street, Al Muteena, Deira, Dubai, 123, United Arab Emirates",
      address_coord: null,
      distance: 1.85,
      country: "Philippines",
      state: "National, Capital Region",
      city: "Parañaque ",
      rating: 4.0,
      reviews: 10,
      profile_images: "1652858800322.jpg,1652858828620.jpg,1652860533273.jpg",
      doc_status: null,
      status: "pending",
      ts: "2021-09-10T19:53:02.000Z",
    },
  },
];

const TaskBidding: React.FC<any> = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  // const [currentModal, setCurrentModal] = useState<any>("");
  const [currentData, setCurrentData] = useState<any>();
  const [taskOffers, setTaskOffers] = useState<any>(taskOffersData);

  // const showModal = (modalName: any) => {
  //   setCurrentModal(MODALS[modalName].toString().toLowerCase());
  //   setIsModalOpen(true);
  // };

  const onClickItem = (id: number) => {
    // alert("onClickItem id: " + id );
    const task = data.find((dat: any) => dat.id === id);
    setCurrentData(task);
    setIsModalOpen(true);
  };
  return (
    <>
      <ModalBiddingDetails
        // component={currentModal}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        data={currentData}
        taskOffers={taskOffers}
        setTaskOffers={setTaskOffers}
      />

      <IonCard color="none" at-default>
        <IonCardContent>
          <AtListItem
            data={data}
            isMergedLine2={false}
            onClickItem={onClickItem}
            isChatEnable={true}
            isTextWrap={true}
          />
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default TaskBidding;
