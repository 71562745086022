import { IonCard, IonCardContent } from "@ionic/react";
import React, { useState } from "react";
import { MODALS } from "..";
import AtListItem from "../../../common/AtListItem";
import ModalTaskStatus from "./ModalTaskStatus";

const data = [
  {
    id: 2,
    photo: "geraldine_tividad.jpg",
    title: "Geraldine Tividad",
    title2: "Approved",
    subtitle: "Iguig, Cagayan Valley, Philippines",
    subtitle2: "28 Feb, 10:25 AM",
    remarks: "Bathroom fittings",
    remarks2: "₱200 per hour",
    subremarks:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio maiores consectetur, dignissimos optio accusantium error a assumenda velit. Vero quidem architecto debitis quis quasi fuga totam sed nisi sequi soluta!",
    cssItem: "",
    cssTitle2: "color-success",

    cost: "₱200 per hour",
    address: "Iguig, Cagayan Valley, Philippines",
    latlong: "",
  },
  {
    id: 3,
    photo: "auric_gavin.jpg",
    title: "Auric Gavin",
    title2: "Rejected",
    subtitle: "San Jose, Antipolo, Philippines",
    subtitle2: "28 Feb, 10:25 AM",
    remarks: "Solar Panel Installation",
    remarks2: "₱250 per hour",
    subremarks: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. ",
    cssItem: "",
    cssTitle2: "color-danger",

    cost: "₱250 per hour",
    address: "San Jose, Antipolo, Philippines",
    latlong: "",
  },
  {
    id: 1,
    photo: "randy_legaspi.jpg",
    title: "Randy Legaspi",
    title2: "New Task",
    subtitle: "Camiling, Tarlac, Philippines",
    subtitle2: "19 Dec, 11:30 AM",
    remarks: "Interior Decorations",
    remarks2: "₱100 per hour",
    subremarks:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio fugit maxime id nemo alias quam delectus nesciunt nobis doloribus at, sit velit eligendi quisquam aut nulla voluptates rem! Ullam, aliquam?",
    cssItem: "at-border-primary",
    cssTitle2: "color-primary",

    cost: "₱100 per hour",
    address: "Camiling, Tarlac, Philippines",
    latlong: "",
  },
];

const TaskHistory: React.FC<any> = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<any>("");
  const [currentItem, setCurrentItem] = useState<any>();
  const showModal = (modalName: any) => {
    setCurrentModal(MODALS[modalName].toString().toLowerCase());
    setIsModalOpen(true);
  };

  const onClickItem = (id: number) => {
    showModal(MODALS.TASK_STATUS);
    // alert("onClickItem id: " + id );
    setCurrentItem(data.find((dat: any) => dat.id === id));
  };
  return (
    <>
      {currentModal && (
        <ModalTaskStatus
          component={currentModal}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          data={currentItem}
          isReadOnly={true}
        />
      )}

      <IonCard color="none" at-default>
        <IonCardContent>
          <AtListItem
            data={data}
            isMergedLine2={false}
            onClickItem={onClickItem}
            isChatEnable={true}
            isTextWrap={true}
          />
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default TaskHistory;
