import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonCardTitle,
  IonCardSubtitle,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import React, { useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import ModalSendMessage from "../ModalSendMessage";
// import { formatDateTime } from "../../../../utils/helpers";
// import AtModalMap from "../../../common/AtModalMap";
// import AvtChat from "../../../common/AvtChat";
// import StarRatings from "react-star-ratings";

const ModalBrowseDetails: React.FC<any> = ({
  isOpen,
  onClose,
  data,
  isReadOnly = false,
}) => { 
  const [isOpenSendMessage, setIsOpenSendMessage] = useState<boolean>(false);
  // const [state, setState] = useState<any>({
  //   status: "Pending",
  // });
 
  const onDismiss = () => {
    onClose(1);
  };
 
  return (
    <>
      <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
        <IonHeader color="none">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>
                <IonIcon icon={arrowBackOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle className="at-center">Provider Details</IonTitle>
            <IonButtons slot="end">
              <IonButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" at-default-modal>
          <IonCard color="none" at-default>
            <IonCardContent>
              <IonList>
                <div className="at-item-list">
                  <img
                    alt="provider"
                    className="task-image"
                    src={`/assets/images/${data?.photo}`}
                    width="60"
                  ></img>
                  <IonItem lines="none">
                    <IonLabel position="stacked" slot="start"></IonLabel>
                    {data?.title && (
                      <IonCardTitle className="at-size-16">
                        <span className="column at-size-18">{data?.title}</span>
                        <span className="at-right color-primary">
                          {data?.title2}
                        </span>
                      </IonCardTitle>
                    )}
                    {data?.subtitle && (
                      <IonCardSubtitle className="at-size-12 at-margin-5-top">
                        <span className="at-text-wrap column">
                          {data?.subtitle}
                        </span>
                        <span
                        // className={`${isMergedLine2 ? "" : "column-2 at-right"}`}
                        >
                          {data?.subtitle2}
                        </span>
                      </IonCardSubtitle>
                    )}
                    {/*                   
                    <IonCardSubtitle className="at-size-12 at-margin-20-top">
                      <span className="column">Task Category</span>
                      <span className="column">Task Cost</span>
                    </IonCardSubtitle>
                    <IonCardTitle className="at-size-14 at-margin-5-top">
                      <span className="column">{data?.remarks}</span>
                      <span className="column">{data?.remarks2}</span>
                    </IonCardTitle> */}

                    <IonCardSubtitle className="at-size-12 at-margin-20-top">
                      <span>Description</span>
                    </IonCardSubtitle>
                    <IonCardTitle className="at-size-14 at-margin-5-top">
                      <span>{data?.subremarks}</span>
                    </IonCardTitle>

                    <span className="at-margin-10-top"></span>
                  </IonItem>
                </div>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonContent>
        <div className="at-menu-bottom">
          <IonButton expand="block" onClick={() => setIsOpenSendMessage(true)}>
            {/* <IonIcon icon={thumbsUp} className="at-size-14 at-pad-10-right" />{" "} */}
            &nbsp;Send Message
          </IonButton>
          {/* <IonButton expand="block" onClick={saveInfo}>
            &nbsp;Book Provider
          </IonButton>
          <IonButton expand="block" onClick={saveInfo}>
            &nbsp;Book Provider
          </IonButton> */}
        </div>
      </IonModal>

      {/* <AtModalMap
        isOpen={isOpenMaps}
        onClose={() => {
          setIsOpenMaps(false);
        }}
        setResult={setResult}
        destination={{ lat: 25.270083, lng: 55.3203976 }} //-- reef mall
      />

      <AvtChat isOpen={isOpenChat} onClose={() => setIsOpenChat(false)} /> */}

      <ModalSendMessage
        isOpen={isOpenSendMessage}
        onClose={() => {
          setIsOpenSendMessage(false);
        }}
        data={data}
      />
      <>{console.log("data>>>>>", data)}</>
    </>
  );
};

export default ModalBrowseDetails;
//-- Should refresh every 5 minutes if task status is PENDING to check on Booking Confirmation
