import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonCardTitle,
  IonCardSubtitle,
  IonTextarea,
  IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";

const ModalSendMessage: React.FC<any> = ({ isOpen, onClose, data }) => {
  const [state, setState] = useState<any>({
    id: 0,
    message: "",
    isSaveMessage: "on",
  });

  useEffect(() => {
    setState({ ...state, id: data?.id });
    // eslint-disable-next-line
  }, [data]);

  const onDismiss = () => {
    onClose(1);
  };
  const sendMessage = () => {
    alert("Info updated \n" + JSON.stringify(state));
    if (!state?.isSaveMessage) {
      setState({ ...state, message: "" });
    }
    onClose(1);
  };
  return (
    <>
      <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
        <IonHeader color="none">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>
                <IonIcon icon={arrowBackOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle className="at-center">Contact this pro</IonTitle>
            <IonButtons slot="end">
              <IonButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" at-default-modal>
          <IonCard color="none" at-default>
            <IonCardContent>
              <IonList>
                <div className="at-item-list">
                  <img
                    alt="provider"
                    className="task-image"
                    src={`/assets/images/${data?.photo}`}
                    width="60"
                  ></img>
                  <IonItem lines="none">
                    <IonLabel position="stacked" slot="start"></IonLabel>
                    {data?.title && (
                      <IonCardTitle className="at-size-16">
                        <span>To: {data?.title}</span>
                      </IonCardTitle>
                    )}
                    {data?.subtitle && (
                      <IonCardSubtitle className="at-size-12 at-margin-5-top">
                        <span className="at-text-wrap column">
                          {data?.subtitle}
                        </span>
                      </IonCardSubtitle>
                    )}

                    <IonCardSubtitle className="at-margin-20-top">
                      <span>Message</span>
                    </IonCardSubtitle>
                    <IonTextarea
                      value={state?.message}
                      className="at-pad-10"
                      rows={10}
                      style={{ border: "1px solid #999", borderRadius: "5px" }}
                      onIonChange={(e) =>
                        setState({ ...state, message: e.detail.value! })
                      }
                    />
                    <span className="at-margin-10-top"></span>
                  </IonItem>
                  <IonItem lines="none" disabled={false}>
                    <IonCardSubtitle>
                      Save your message to save time – easily contact more
                      providers
                    </IonCardSubtitle>
                    <IonToggle
                      slot="start"
                      checked={state?.isSaveMessage}
                      onIonChange={(e) =>
                        setState({
                          ...state,
                          isSaveMessage: e.detail.checked!,
                        })
                      }
                    ></IonToggle>
                  </IonItem>
                </div>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonContent>
        <div className="at-menu-bottom">
          <IonButton expand="block" onClick={onDismiss}>
            Cancel
          </IonButton>

          <IonButton expand="block" onClick={sendMessage}>
            {/* <IonIcon icon={thumbsUp} className="at-size-14 at-pad-10-right" />{" "} */}
            &nbsp;Send
          </IonButton>
        </div>
      </IonModal>
      <>{console.log(">>>", state?.isSaveMessage)}</>
    </>
  );
};

export default ModalSendMessage;
