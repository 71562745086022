import {
  IonContent,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import ModalPostTask from "./modals/postTask/ModalPostTask";
const allCategories = [
  {
    id: 1,
    name: "Air Conditioning",
    icon: "./assets/images/ac.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:56:46.000Z",
  },
  {
    id: 2,
    name: "Building Maintenance",
    icon: "./assets/images/building-maintenance.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:56:46.000Z",
  },
  {
    id: 3,
    name: "Carpentry",
    icon: "./assets/images/hammer.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 4,
    name: "Electrical",
    icon: "./assets/images/electrical.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 5,
    name: "Home Improvement",
    icon: "./assets/images/home.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 6,
    name: "Painting",
    icon: "./assets/images/paint-roller.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 7,
    name: "Pest Control",
    icon: "./assets/images/pest-control.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 8,
    name: "Plumbing",
    icon: "./assets/images/plumbing.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 9,
    name: "Roofing",
    icon: "./assets/images/roof.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 10,
    name: "Smart Product Installation",
    icon: "./assets/images/cctv.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 11,
    name: "Solar Panel Installation",
    icon: "./assets/images/solar-panels.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 12,
    name: "Tiling",
    icon: "./assets/images/tiles.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 13,
    name: "Landscaping",
    icon: "./assets/images/landscaping.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 14,
    name: "Perimeter Fencing",
    icon: "./assets/images/fencing.png",
    class_name: null,
    width: 44,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
];

/***** data from Randy
 building permit application
autocad design
structural design
built in cabinets
      perimeter fencing
      landscaping
      trusses and roofing
retaining walls and ripraps
      solar panel installations
      tiling works
renovations
extensions
 */
const TabPostTask: React.FC<any> = () => {
  const { auth } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    email: "avtividad@yahoo.com",
    name: "Adonis Tividad",
    mobile_no: "+91771794521",
    category: "Carpentry",
    taskdate: "December 21, 2022",
    tasktime: "8:00 AM",
    task_datetime: "",
    location: "14 Ibayo Street, Malinta, Valenzuela City",
    remarks:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem aliquid veniam dignissimos nulla. Quo, quisquam inventore. At earum debitis iusto, laudantium adipisci eius optio dolores nobis, numquam quae sint ducimus?",
  });
  const onClickCategory = (cat: string) => {
    // alert("onClickCategory");
    // history.push(`/post/${cat.toLowerCase().replaceAll(" ", "_")}`);
    setData({ ...data, category: cat });
    setIsModalOpen(true);
  };

  const getGreeting = () => {
    const today = new Date();
    const curHr = today.getHours();

    if (curHr < 12) {
      return "Good Morning,";
    } else if (curHr < 18) {
      return "Good Afternoon,";
    } else {
      return "Good Evening,";
    }
  };
  return (
    <>
      <IonContent className="ion-padding " scrollEvents={true}>
        <IonCard color="none" at-default>
          <IonCardContent>
            <IonCardTitle className="at-center">
              <small>
                {getGreeting()} {auth?.givenName}! 
              </small>
            </IonCardTitle>
            <IonCardSubtitle className="at-center">
              What do you need help with?
            </IonCardSubtitle>
          </IonCardContent>
        </IonCard>

        <IonCard color="none" at-default>
          <IonCardContent>
            <div className="categories pad-10-left">
              {allCategories &&
                allCategories?.map((cat: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="category at-center cursor-pointer"
                      onClick={() => onClickCategory(cat.name)}
                    >
                      <div className="cat-icon">
                        <img
                          src={cat.icon}
                          alt="icon"
                          width={cat.width}
                          // className={cat.className}
                          className="pad-15"
                        />
                      </div>
                      <div className="cat-text size-12 at-center">
                        {cat.name}
                      </div>
                    </div>
                  );
                })}
            </div>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <ModalPostTask
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        data={data}
        setData={setData}
        allCategories={allCategories}
      />
    </>
  );
};

export default TabPostTask;
