import React, { useState } from "react";
import { IonCard, IonCardContent, IonContent } from "@ionic/react";
import AtListItem from "../common/AtListItem";
import AtSearchBar from "../common/AtSearchBar";
import { GetStarRating } from "./modals/tasks/bidding/GetStarRating";
import ModalBrowseDetails from "./modals/browse/ModalBrowseDetails";

const data = [
  {
    id: 1,
    photo: "randy_legaspi.jpg",
    title: "Randy Legaspi",
    title2: "Interior Decorations",
    subtitle: GetStarRating({ rating: 3.5, reviews: 25 }),
  
    subtitle2: "₱100 per hour",
    remarks: "",
    remarks2:  "",
    subremarks:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio fugit maxime id nemo alias quam delectus nesciunt nobis doloribus at, sit velit eligendi quisquam aut nulla voluptates rem! Ullam, aliquam?",
    cssItem: "at-border-primary",
    cssTitle2: "",

    cost: "₱100 per hour",
    address: "Camiling, Tarlac, Philippines",
    latlong: "",
  },
  {
    id: 2,
    photo: "geraldine_tividad.jpg",
    title: "Geraldine Tividad",
    title2: "Bathroom fittings",
    subtitle: GetStarRating({ rating: 4.5, reviews: 21 }),
    // subtitle: "Iguig, Cagayan Valley, Philippines",
    subtitle2: "₱200 per hour",
    remarks: "",
    remarks2: "",
    subremarks:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio maiores consectetur, dignissimos optio accusantium error a assumenda velit. Vero quidem architecto debitis quis quasi fuga totam sed nisi sequi soluta!",
    cssItem: "",
    cssTitle2: "color-primary",

    cost: "₱200 per hour",
    address: "Iguig, Cagayan Valley, Philippines",
    latlong: "",
  },
  {
    id: 3,
    photo: "auric_gavin.jpg",
    title: "Auric Gavin",
    title2: "Solar Panel Installation",
    subtitle: GetStarRating({ rating: 2.9, reviews: 25 }),
    // subtitle: "San Jose, Antipolo, Philippines",
    subtitle2: "₱250 per hour",
    remarks: "",
    remarks2: "",
    subremarks: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. ",
    cssItem: "",
    cssTitle2: "color-primary",

    cost: "₱250 per hour",
    address: "San Jose, Antipolo, Philippines",
    latlong: "",
  },
  {
    id: 4,
    photo: "randy_legaspi.jpg",
    title: "Randy Legaspi",
    title2: "Interior Decorations",
    subtitle: GetStarRating({ rating: 3.9, reviews: 13 }),
    // subtitle: "Camiling, Tarlac, Philippines",
    subtitle2: "₱100 per hour",
    remarks: "",
    remarks2: "",
    subremarks:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio fugit maxime id nemo alias quam delectus nesciunt nobis doloribus at, sit velit eligendi quisquam aut nulla voluptates rem! Ullam, aliquam?",
    cssItem: "at-border-primary",
    cssTitle2: "color-primary",

    cost: "₱100 per hour",
    address: "Camiling, Tarlac, Philippines",
    latlong: "",
  },
  {
    id: 5,
    photo: "geraldine_tividad.jpg",
    title: "Geraldine Tividad",
    title2: "Bathroom fittings",
    subtitle: GetStarRating({ rating: 4.8, reviews: 21 }),
    // subtitle: "Iguig, Cagayan Valley, Philippines",
    subtitle2: "₱200 per hour",
    remarks: "",
    remarks2: "",
    subremarks:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio maiores consectetur, dignissimos optio accusantium error a assumenda velit. Vero quidem architecto debitis quis quasi fuga totam sed nisi sequi soluta!",
    cssItem: "",
    cssTitle2: "color-primary",

    cost: "₱200 per hour",
    address: "Iguig, Cagayan Valley, Philippines",
    latlong: "",
  },
  {
    id: 6,
    photo: "auric_gavin.jpg",
    title: "Auric Gavin",
    title2: "Solar Panel Installation",
    subtitle: GetStarRating({ rating: 4.1, reviews: 20 }),
    // subtitle: "San Jose, Antipolo, Philippines",
    subtitle2: "₱250 per hour",
    remarks: "",
    remarks2:"",
    subremarks: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. ",
    cssItem: "",
    cssTitle2: "color-primary",

    cost: "₱250 per hour",
    address: "San Jose, Antipolo, Philippines",
    latlong: "",
  },
  {
    id: 7,
    photo: "randy_legaspi.jpg",
    title: "Randy Legaspi",
    title2: "Interior Decorations",
    subtitle: GetStarRating({ rating: 3.9, reviews: 15 }),
    // subtitle: "Camiling, Tarlac, Philippines",
    subtitle2: "₱100 per hour",
    remarks: "",
    remarks2: "",
    subremarks:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio fugit maxime id nemo alias quam delectus nesciunt nobis doloribus at, sit velit eligendi quisquam aut nulla voluptates rem! Ullam, aliquam?",
    cssItem: "at-border-primary",
    cssTitle2: "color-primary",

    cost: "₱100 per hour",
    address: "Camiling, Tarlac, Philippines",
    latlong: "",
  },
  {
    id: 8,
    photo: "geraldine_tividad.jpg",
    title: "Geraldine Tividad",
    title2: "Bathroom fittings",
    subtitle: GetStarRating({ rating: 4.3, reviews: 22 }),
    // subtitle: "Iguig, Cagayan Valley, Philippines",
    subtitle2: "₱200 per hour",
    remarks: "",
    remarks2: "",
    subremarks:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio maiores consectetur, dignissimos optio accusantium error a assumenda velit. Vero quidem architecto debitis quis quasi fuga totam sed nisi sequi soluta!",
    cssItem: "",
    cssTitle2: "color-primary",

    cost: "₱200 per hour",
    address: "Iguig, Cagayan Valley, Philippines",
    latlong: "",
  },
  {
    id: 9,
    photo: "auric_gavin.jpg",
    title: "Auric Gavin",
    title2: "Solar Panel Installation",
    subtitle: GetStarRating({ rating: 3.8, reviews: 23 }),
    // subtitle: "San Jose, Antipolo, Philippines",
    subtitle2: "₱250 per hour",
    remarks: "",
    remarks2: "",
    subremarks: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. ",
    cssItem: "",
    cssTitle2: "color-primary",

    cost: "₱250 per hour",
    address: "San Jose, Antipolo, Philippines",
    latlong: "",
  },
  {
    id: 10,
    photo: "randy_legaspi.jpg",
    title: "Randy Legaspi",
    title2: "Interior Decorations",
    subtitle: GetStarRating({ rating: 4.7, reviews: 27 }),
    // subtitle: "Camiling, Tarlac, Philippines",
    subtitle2: "₱100 per hour",
    remarks: "",
    remarks2: "",
    subremarks:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio fugit maxime id nemo alias quam delectus nesciunt nobis doloribus at, sit velit eligendi quisquam aut nulla voluptates rem! Ullam, aliquam?",
    cssItem: "at-border-primary",
    cssTitle2: "color-primary",

    cost: "₱100 per hour",
    address: "Camiling, Tarlac, Philippines",
    latlong: "",
  },
  {
    id: 12,
    photo: "geraldine_tividad.jpg",
    title: "Geraldine Tividad",
    title2: "Bathroom fittings",
    subtitle: GetStarRating({ rating: 4.6, reviews: 16 }),
    // subtitle: "Iguig, Cagayan Valley, Philippines",
    subtitle2: "₱200 per hour",
    remarks: "",
    remarks2:  "",
    subremarks:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio maiores consectetur, dignissimos optio accusantium error a assumenda velit. Vero quidem architecto debitis quis quasi fuga totam sed nisi sequi soluta!",
    cssItem: "",
    cssTitle2: "color-primary",

    cost: "₱200 per hour",
    address: "Iguig, Cagayan Valley, Philippines",
    latlong: "",
  },
  {
    id: 13,
    photo: "auric_gavin.jpg",
    title: "Auric Gavin",
    title2: "Solar Panel Installation",
    subtitle: GetStarRating({ rating: 3.7, reviews: 24 }), 
    subtitle2: "₱250 per hour",
    remarks: "",
    remarks2:  "",
    subremarks: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. ",
    cssItem: "",
    cssTitle2: "color-primary",

    cost: "₱250 per hour",
    address: "San Jose, Antipolo, Philippines",
    latlong: "",
  },
];

const TabBrowse: React.FC<any> = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [currentItem, setCurrentItem] = useState<any>();

  const onClickItem = (id: number) => {
    setIsModalOpen(true);
    // alert("onClickItem id: " + id);
    setCurrentItem(data.find((dat: any) => dat.id === id));
  };
  return (
    <>
      <ModalBrowseDetails
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        data={currentItem}
        isReadOnly={true}
      />

      <IonContent className="ion-padding " scrollEvents={true}>
        <AtSearchBar setSearchText={setSearchText} />
        <IonCard color="none" at-default>
          <IonCardContent>
            <AtListItem
              data={data}
              isMergedLine2={false}
              onClickItem={onClickItem}
              isTextWrap={true}
            />
          </IonCardContent>
        </IonCard>
      </IonContent>
      {console.log("searchText>>>", searchText)}
    </>
  );
};

export default TabBrowse;
